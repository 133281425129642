@import '../../assets/css/breakpoints.scss';

.popup-container {
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 30;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: rgba(0, 0, 0, 0.5);
  // z-index: -1;
}

.bg-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.horizontal-slider {
  width: 100%;
  height: 60px;
}

.example-thumb {
  font-size: 12px;
  font-family: 'Nunito', sans-serif;
  font-weight: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  color: white;
  cursor: pointer;
  border: 2px solid #63C127;
  border-radius: 50px;
  box-sizing: border-box;
  outline: none;
}

// .example-thumb.active {
//   background-color: #474851;
// }

.example-track {
  position: relative;
  background: #63C127;
}

.example-track.example-track-1 {
  background: #474851;
}

.horizontal-slider .example-track {
  top: 30px;
  height: 2px;
}

.horizontal-slider .example-thumb {
  top: 1px;
  width: 60px;
  height: 60px;
  line-height: 38px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* hide scrollbar but allow scrolling */
.non-scrollbar-box {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.non-scrollbar-box::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}