@import '../../assets/css/breakpoints.scss';

.root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .container {
        text-align: center;
        width: 100%;
        max-width: 500px;
        border-radius: 12px;
        padding: 24px;
        background-color: white;
        z-index: 300;
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h4 {
                font-family: 'Nunito', sans-serif;
                font-size: 20px;
            }
            img {
                width: 16px;
                height: 16px;
            }
        }
        .body {
            .walletItem {
                border-radius: 6px;
                // border: 1px solid #31343e;
                background-color: #f5f5f5;
                padding: 17px;
                margin-top: 20px;
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                }
                .walletLabel {
                    font-family: 'Nunito', sans-serif;
                    font-size: 16px;
                }
                .walletIcon {
                    width: 27px;
                    // height: 27px;
                }
            }
        }
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0,0,0,0.7);
        z-index: -1;
    }
}