@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  --primary-color: #1C2021;
  --secondary-color: #272833;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--primary-color);
  color: white;
}
